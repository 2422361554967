import { T } from '@tolgee/react';

import FormattedCheckboxField from '../../../../components/translation/FormattedCheckboxField';
import FormattedInputField from '../../../../components/translation/FormattedInputField';
import FormattedSubmitButton from '../../../../components/translation/FormattedSubmitButton';

import { GqlExportSerieInput, useExportSerieMutation } from '#gql';
import { FormattedAlert } from '#intl';
import { FormRHF } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { assert } from '#utils/assert';

export interface PasswordStepProps {
  serieId: string;
  setJobId: (jobId: string) => void;
}

type FormValues = Omit<GqlExportSerieInput, 'seriesId'>;

const defaultValues: FormValues = {
  password: '',
  withAttachments: true,
  withImages: true,
  withLastPublishedAlert: true,
};

export function ConfigurationStep(props: PasswordStepProps) {
  const { setJobId, serieId } = props;
  const [exportSerie] = useExportSerieMutation();

  async function onSubmit(values: FormValues) {
    const jobId = await exportSerie({
      variables: { input: { ...values, seriesId: serieId } },
    });

    if (jobId.errors) {
      for (const error of jobId.errors) {
        reportError(error);
      }
    }
    assert(jobId.data);

    setJobId(jobId.data.exportSerie);
  }

  return (
    <FormRHF defaultValues={defaultValues} onSubmit={onSubmit}>
      <CardLayout
        title="series_exchange.export.configuration"
        footerClassName="text-right"
        footer={
          <FormattedSubmitButton messageId="page.series.export.card.security.footer.export" />
        }
        bodyClassName="space-y-5"
      >
        <fieldset className="space-y-2">
          <legend className="font-semibold">
            <T keyName="page.series.export.card.security.title" />
          </legend>

          <div className="whitespace-pre-line">
            <FormattedAlert
              type="info"
              messageId="page.series.export.card.security.description"
            />
          </div>

          <FormattedInputField
            name="password"
            label="page.series.export.card.security.field.password.label"
            autoComplete="off"
            type="text"
          />
        </fieldset>

        <fieldset className="space-y-1">
          <legend>
            <T keyName="series_exchange.export.with.label" />
          </legend>
          <FormattedCheckboxField
            label="series_exchange.export.withAttachments"
            name="withAttachments"
          />
          <FormattedCheckboxField
            label="series_exchange.export.withImages"
            name="withImages"
          />
          <FormattedCheckboxField
            label="series_exchange.export.withLastPublishedAlert"
            name="withLastPublishedAlert"
          />
        </fieldset>
      </CardLayout>
    </FormRHF>
  );
}
