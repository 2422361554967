import { PencilSquareIcon, ShareIcon } from '@heroicons/react/20/solid';
import {
  DocumentDuplicateIcon,
  PencilIcon,
  ShieldExclamationIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { T, useTranslate } from '@tolgee/react';
import { ComponentType, Fragment, memo } from 'react';
import { FormattedDate } from 'react-intl';
import { Link, LinkProps, useNavigate } from 'react-router-dom';

import { Feature } from '../../constants';
import { useDocumentsContext } from '../../contexts/documents';
import { useHasPermission } from '../../hooks/permissions';
import { useSeqIdParam } from '../../hooks/useParams';
import IfHasFeature from '../IfHasFeature';
import {
  SerieCountry,
  SerieFraudType,
  SerieManagers,
  SerieProfile,
  SerieType,
} from '../ViewComponents';
import { AlertWidget } from '../alerts/AlertWidget';
import CreateAlertDialog from '../alerts/modals/CreateAlertDialog';
import { useExperimentalFeatures } from '../experimental-features/useExperimentalFeatures';
import IfHasPermission from '../permissions/IfHasPermission';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import { UserChangesMeta } from '../user/UserChangesMeta';

import DeleteSerieModal from './DeleteSerieModal';
import DeleteSerieWarnHasAlertModal from './DeleteSerieWarnHasAlertModal';
import { ImportMetadataCard } from './ImportMetadataCard';
import SerieDocuments from './SerieDocuments';
import { SerieHeadingBreadcrumb } from './SerieHeading';
import UpdateSerieNameDialog from './UpdateSerieNameDialog';

import { GqlSerieQuery, useSerieQuery } from '#gql';
import {
  SeriesImagesContainer,
  SeriesImagesCard,
  SeriesAttachment,
  SeriesAttachmentsCard,
  SeriesAttachmentsContainer,
  SeriesImageOverlay,
  SeriesImage,
} from '#series';
import {
  assertUnreachable,
  Button,
  ButtonGroup,
  ButtonGroupButton,
  ButtonGroupDropdown,
  DropdownActionOption,
  DropdownProps,
  useOnOff,
} from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { HighlightedLink } from '#ui/link';
import { PartialOverlay } from '#ui/overlay';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';

function ViewSerieBase() {
  const { t } = useTranslate();
  const seqId = useSeqIdParam();
  const { store } = useDocumentsContext();
  const { loading, error, data } = useSerieQuery({
    variables: { seqId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  } else if (loading || !data) {
    return <T keyName="global.loading" />;
  }

  const serie = data.serie;
  const { nodes: documents, totalCount } = serie.documents;

  const subject = `[${t('series.singular_label', { noWrap: true })}] ${serie.seqId} - ${serie.name}`;
  const body = window.location.href;

  return (
    <PageLayout
      title={<SerieHeadingBreadcrumb serie={serie} />}
      navigation={
        <PageLayoutNavigation to="/series">
          <T keyName="nav.series.list" />
        </PageLayoutNavigation>
      }
      actions={<EditButtons serie={serie} />}
    >
      <div className="flex items-start justify-between">
        <div>
          {serie.currentAlert && <AlertWidget alert={serie.currentAlert} />}
        </div>
        <UserChangesMeta {...serie} mailSubject={subject} mailBody={body}>
          {serie.aliases.length > 0 && (
            <div className="flex flex-row gap-2">
              {t('page.series.view.serie_aliases')}
              {serie.aliases.map((alias) => (
                <Fragment key={alias}>
                  <span className="italic" key={alias}>
                    {alias}
                  </span>
                  <span className="last:hidden">, </span>
                </Fragment>
              ))}
            </div>
          )}
        </UserChangesMeta>
      </div>
      <div className="flex flex-row gap-5">
        <div className="flex flex-1 flex-col gap-5">
          <CardLayout title="page.series.view.general_information">
            <SerieType docType={serie.docType} />
            <SerieFraudType fraudType={serie.docFraudType} />
            <SerieCountry country={serie.docCountry} />
            <SerieManagers managers={serie.managers} />
          </CardLayout>

          <CardLayout title="series.field.contextual_profile">
            <SerieProfile profile={serie.contextualProfile} />
          </CardLayout>

          <CardLayout title="series.field.material_profile">
            <SerieProfile profile={serie.materialProfile} />
          </CardLayout>

          <SeriesAttachmentsCard extraTitle={<ManageFileButton />}>
            <SeriesAttachmentsContainer
              attachments={serie.attachments}
              renderAttachments={(attachment) => (
                <SeriesAttachment attachment={attachment} />
              )}
            />
          </SeriesAttachmentsCard>

          <SeriesImagesCard extraTitle={<ManageFileButton />}>
            <SeriesImagesContainer
              images={serie.images}
              renderImage={(image) => (
                <SeriesImageOverlay
                  Overlay={PartialOverlay}
                  label={image.filename}
                >
                  <SeriesImage image={image} />
                </SeriesImageOverlay>
              )}
            />
          </SeriesImagesCard>
        </div>

        <IfHasFeature feature={Feature.documents}>
          <div className="flex w-1/3 flex-1 flex-col gap-5 self-start">
            <CardLayout
              bodyClassName="!p-0"
              title="page.series.view.series_docs"
              footerClassName="!border-t-0 text-right"
              footer={
                totalCount > 0 && (
                  <HighlightedLink
                    onClick={store.reset}
                    to={`/documents?series=${serie.id}`}
                  >
                    <T
                      keyName="page.series.view.go_to_full_document_list"
                      params={{ totalCount }}
                    />
                  </HighlightedLink>
                )
              }
            >
              {serie.seizureSpan && (
                <div className="flex flex-col items-start justify-between border-b border-neutral-200 px-4 py-5 sm:p-6">
                  <div className="text-sm text-neutral-500">
                    <div className="text-sm text-neutral-900">
                      <div>
                        {t('pages.series.view.seizure_dates', {
                          firstSeizureDate: (
                            <b key="firstSeizureDate">
                              {serie.firstSeizureDate && (
                                <FormattedDate value={serie.firstSeizureDate} />
                              )}
                            </b>
                          ),
                          lastSeizureDate: (
                            <b key="lastSeizureDate">
                              {serie.lastSeizureDate && (
                                <FormattedDate value={serie.lastSeizureDate} />
                              )}
                            </b>
                          ),
                        })}
                        {serie.firstSeizureDate !== serie.lastSeizureDate && (
                          <div>
                            {t('pages.series.view.seizure_span', {
                              seizureSpan: (
                                <b key="seizureSpan">{serie.seizureSpan}</b>
                              ),
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="px-4 py-5 sm:p-6">
                <SerieDocuments documents={documents} />
                {documents.length < totalCount && '…'}
              </div>
            </CardLayout>

            {serie.importMetadata && serie.importedAt && (
              <ImportMetadataCard
                metadata={serie.importMetadata}
                importedAt={serie.importedAt}
                importFirstSeizureDate={serie.importFirstSeizureDate}
                importLastSeizureDate={serie.importLastSeizureDate}
                importDocumentCount={serie.importDocumentCount}
              />
            )}
          </div>
        </IfHasFeature>
      </div>
    </PageLayout>
  );
}

function ManageFileButton() {
  return (
    <IfHasPermission userPermission="serie_update">
      <div className="absolute right-0 top-0 flex h-full flex-col items-center justify-around pr-4 sm:pr-6">
        <Button
          as={Link}
          to="./files/manage"
          roundness="circular"
          variant="primary"
          color="primary"
        >
          <PencilIcon className="h-5 w-5" />
        </Button>
      </div>
    </IfHasPermission>
  );
}

type DropdownAction =
  | 'editName'
  | 'delete'
  | 'merge'
  | 'createAlert'
  | 'export';

function EditButtons(props: { serie: GqlSerieQuery['serie'] }) {
  const { serie } = props;

  const navigate = useNavigate();
  const { featureSet } = useExperimentalFeatures();
  const canMerge = useHasPermission('serie_merge');
  const canDelete = useHasPermission('serie_delete');
  const canEdit = useHasPermission('serie_update');
  const canCreateAlert = useHasPermission('alert_create');
  const [isDeleting, openDeleteModal, closeDeleteModal] = useOnOff();
  const [isUpdatingName, openUpdateNameModal, closeUpdateNameModal] =
    useOnOff();

  const [isCreatingAlert, openCreateAlertModal, closeCreateAlertModal] =
    useOnOff();

  const editDropdownOptions: DropdownProps<DropdownAction>['options'] = [];
  const first: Array<DropdownActionOption<DropdownAction>> = [];
  if (canEdit) {
    first.push({
      type: 'action',
      data: 'editName',
      label: <T keyName="page.series.view.edit_name" />,
      icon: <PencilSquareIcon />,
    });
  }
  if (canMerge) {
    first.push({
      type: 'action',
      data: 'merge',
      label: <T keyName="page.series.view.merge" />,
      icon: <ShareIcon className="rotate-180" />,
    });
  }

  if (featureSet.has('series_exchange')) {
    first.push({
      type: 'action',
      data: 'export',
      label: <T keyName="page.series.export" />,
      icon: <DocumentDuplicateIcon />,
    });
  }

  editDropdownOptions.push(first);

  if (canCreateAlert && !serie.currentAlert) {
    first.push({
      type: 'action',
      data: 'createAlert',
      label: <T keyName="page.series.view.create_alert" />,
      icon: <ShieldExclamationIcon />,
    });
  }
  if (canDelete) {
    editDropdownOptions.push([
      {
        type: 'action',
        data: 'delete',
        label: (
          <div className="text-danger-600">
            <T keyName="global.delete" />
          </div>
        ),
        icon: <TrashIcon className="text-danger-600" />,
      },
    ]);
  }

  return (
    <>
      <IfHasPermission userPermission="serie_update">
        <ButtonGroup variant="white">
          <ButtonGroupButton<ComponentType<LinkProps>> as={Link} to="edit">
            <div className="flex items-center gap-2">
              <PencilIcon className="h-4 w-4" />
              <T keyName="global.edit" />
            </div>
          </ButtonGroupButton>
          <ButtonGroupDropdown
            renderAction={(Component, option) => {
              if (option.type === 'action' && option.data !== 'export') {
                return <Component>{option.label}</Component>;
              }

              return (
                <Link to="export">
                  <Component>{option.label}</Component>
                </Link>
              );
            }}
            options={editDropdownOptions}
            onSelect={(option) => {
              const action = option.data;
              assert(action);
              switch (action) {
                case 'delete':
                  openDeleteModal();
                  break;
                case 'merge':
                  navigate('merge');
                  break;
                case 'editName':
                  openUpdateNameModal();
                  break;
                case 'createAlert':
                  openCreateAlertModal();
                  break;
                case 'export':
                  // handled by renderAction to be a real Link
                  break;
                default:
                  assertUnreachable(action);
              }
            }}
          />
        </ButtonGroup>
      </IfHasPermission>
      {isDeleting &&
        (serie.currentAlert ? (
          <DeleteSerieWarnHasAlertModal
            onRequestClose={closeDeleteModal}
            serieName={serie.name}
          />
        ) : (
          <DeleteSerieModal
            onRequestClose={closeDeleteModal}
            onSuccess={() => {
              navigate('/series');
            }}
            serie={serie}
          />
        ))}
      {isUpdatingName && (
        <UpdateSerieNameDialog
          onRequestClose={closeUpdateNameModal}
          serie={serie}
        />
      )}
      {isCreatingAlert && (
        <CreateAlertDialog onClose={closeCreateAlertModal} serie={serie} />
      )}
    </>
  );
}

const ViewSerie = memo(ViewSerieBase);
export default ViewSerie;
