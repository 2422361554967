import { createEditor } from 'lexical';

const config = {
  namespace: 'EditorValidation',
  onError: () => {},
};

export function validateRichText(value: string) {
  const editor = createEditor(config);

  try {
    const state = editor.parseEditorState(value);
    editor.setEditorState(state);

    return true;
  } catch {
    return false;
  }
}
