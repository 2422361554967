import { T } from '@tolgee/react';
import { SerieExportStatus } from '@zakodium/profid-shared';
import clsx from 'clsx';
import { useEffect } from 'react';

import FormattedButton from '../../../../components/translation/FormattedButton';
import FormattedErrorAlert from '../../../../components/translation/FormattedErrorAlert';
import { apiUrl } from '../../../../constants';
import {
  DisplayableExportSerieStatus,
  safeTranslationKeyExportSerie,
} from '../../../../translationsMapping/exportSerie';

import { useSerieExportStatusQuery } from '#gql';
import { CardLayout } from '#ui/card_layout';

export interface ExportProgressionStepProps {
  clearJobId: () => void;
  jobId: string;
  seqId: string;
}

const events: DisplayableExportSerieStatus[] = [
  'seriesExported',
  'seriesImagesExported',
  'seriesAttachmentsExported',
  'publicationFileExported',
  'publicationExported',
  'ready',
];

export function ExportProgressionStep(props: ExportProgressionStepProps) {
  const { clearJobId, jobId, seqId } = props;

  const result = useSerieExportStatusQuery({
    variables: { jobId },
    pollInterval: 1000,
  });

  const data = result.data ?? result.previousData;
  const { error, stopPolling } = result;

  useEffect(() => {
    const shouldStop =
      data?.serieExportStatus?.status === 'ready' || result.error;
    if (shouldStop) stopPolling();
  }, [data, result.error, stopPolling]);

  if (error) {
    return <FormattedErrorAlert error={error} onDismiss={clearJobId} />;
  }
  if (!data?.serieExportStatus?.status) return null;

  const status = data.serieExportStatus
    .status satisfies SerieExportStatus as DisplayableExportSerieStatus;
  const percent = ((events.indexOf(status) + 1) * 100) / events.length;

  return (
    <CardLayout
      title="page.series.export.card.information.title"
      cardClassName="flex-1"
      footerClassName="text-right"
      footer={
        <div className="flex flex-row justify-between">
          <FormattedButton
            messageId="global.back"
            onClick={clearJobId}
            variant="white"
          />
          {status === 'ready' && (
            <FormattedButton
              as="a"
              messageId="page.series.export.card.information.footer.download"
              href={`${apiUrl}/files/series/${seqId}/export/${jobId}?download=true`}
              download={`${seqId}.pfd`}
            />
          )}
        </div>
      }
    >
      <div className="flex flex-col gap-5">
        <div className="mt-6" aria-hidden="true">
          <div className="overflow-hidden rounded-md border-[3px] border-neutral-200 bg-neutral-200">
            <div
              className="h-2 animate-pulse rounded-md bg-primary-600"
              style={{ width: `${percent}%` }}
            />
          </div>

          <div className="mt-6 hidden grid-cols-6 text-sm font-medium text-neutral-600 sm:grid">
            {events.map((event, index) => (
              <div
                key={event}
                className={clsx(
                  'text-center first:text-left last:text-right',
                  events.indexOf(status) >= index && 'text-primary-600',
                )}
              >
                <T keyName={safeTranslationKeyExportSerie(event)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </CardLayout>
  );
}
