import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import {
  formatSeriesSeqId,
  SeriesExchangeExtractor,
  SeriesRules,
} from '@zakodium/profid-shared';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedList } from 'react-intl';
import { match, P } from 'ts-pattern';
import { useDebounceCallback } from 'usehooks-ts';

import {
  useSeriesCreationIdentifiersAvailableLazyQuery,
  useSerieInstanceUrlQuery,
} from '#gql';
import { FormattedAlert, FormattedButton } from '#intl';
import { Input, Spinner } from '#tailwind_ui';
import { HighlightedAnchor, HighlightedLink } from '#ui/link';
import { assert } from '#utils/assert';

export interface ConflictNameProps {
  extractor: SeriesExchangeExtractor;
  children: ReactNode;
  onPreviousStep: () => void;
  onImport: (newName?: string) => void;
}

export function ConflictManager(props: ConflictNameProps) {
  const series = props.extractor.getSeries().at(0);
  const application = props.extractor.getApplication();
  assert(series);

  const [name, setName] = useState<string>(series.name);

  const [runQuery, queryResult] =
    useSeriesCreationIdentifiersAvailableLazyQuery();

  const debouncedRunQuery = useDebounceCallback(runQuery, 500);
  useEffect(() => {
    void debouncedRunQuery({
      variables: { id: series.id, name },
      errorPolicy: 'all',
    });
  }, [debouncedRunQuery, name, series.id]);

  const data = queryResult.data || queryResult.previousData;
  const shouldEditNameRef = useRef<boolean>();
  if (shouldEditNameRef.current === undefined && data) {
    shouldEditNameRef.current = Boolean(data.serieByName) && !data.serieById;
  }
  const shouldNameBeEditable = shouldEditNameRef.current;

  const isNameValid = useMemo(
    () => SeriesRules.validNameRegex.test(name),
    [name],
  );
  const isImportable = useMemo(
    () => isNameValid && data && !data.serieByName && !data.serieById,
    [data, isNameValid],
  );

  const hasUnexpectedError = queryResult.error?.graphQLErrors.every(
    (e) => e.extensions.profid.code !== 'NOT_FOUND',
  );
  if (hasUnexpectedError) {
    throw queryResult.error as Error;
  }
  // if no data, nothing to render
  if (!data) return null;

  // warning if conflict on name
  const alert = match(data)
    .with(
      { serieById: P.select(P.not(P.nullish)) },
      ({ seqId, name, aliases }) => (
        <AlertId
          exportedCode={application.code}
          exportedSeqYear={series.seqYear}
          exportedSeqNumber={series.seqNumber}
          exportedName={series.name}
          seqId={seqId}
          name={name}
          aliases={aliases}
        >
          <FormattedButton
            variant="secondary"
            messageId="global.back"
            onClick={props.onPreviousStep}
          />
        </AlertId>
      ),
    )
    .with(
      { serieByName: P.select(P.not(P.nullish)) },
      ({ seqId, name, aliases }) => (
        <AlertName seqId={seqId} name={name} aliases={aliases} />
      ),
    )
    .otherwise(() => null);

  // render name conflict solving input
  // children
  // import button (go to next step)
  return (
    <section className="space-y-5">
      {shouldNameBeEditable ? (
        <div className="flex items-end gap-5">
          <Input
            label={<T keyName="series.field.name" />}
            name="name"
            defaultValue={name}
            onChange={(evt) => setName(evt.target.value)}
            valid={isImportable}
            leadingAddon={match({ loading: queryResult.loading, data })
              .with({ loading: true }, () => <Spinner className="h-5 px-2" />)
              .with({ data: { serieByName: P.not(P.nullish) } }, () => (
                <XCircleIcon className="h-5 px-2" />
              ))
              .otherwise(() => (
                <CheckCircleIcon className="h-5 px-2" />
              ))}
          />
          {alert}
        </div>
      ) : (
        alert
      )}

      {props.children}

      <FormattedButton
        messageId="series.import"
        disabled={!isImportable}
        onClick={() => props.onImport(name)}
      />
    </section>
  );
}

interface AlertNameProps {
  seqId: string;
  name: string;
  aliases: string[];
}

function AlertName(props: AlertNameProps) {
  const { seqId, name, aliases } = props;

  return (
    <FormattedAlert
      type="warning"
      messageId="series_exchange.import.error.name_conflict"
      messageValues={{
        Link: (part) => (
          <HighlightedLink to={`/series/${seqId}`} target="_blank">
            {part}
            {props.aliases.length > 0 && (
              <>
                , <FormattedList value={aliases} />
              </>
            )}
          </HighlightedLink>
        ),
        seqId,
        name,
      }}
    />
  );
}

interface AlertIdProps {
  exportedCode: string;
  exportedSeqYear: number;
  exportedSeqNumber: number;
  exportedName: string;

  seqId: string;
  name: string;
  aliases: string[];

  children: ReactNode;
}

function AlertId(props: AlertIdProps) {
  const {
    exportedCode,
    exportedSeqYear,
    exportedSeqNumber,
    exportedName,
    seqId,
    name,
    aliases,
  } = props;

  const exportedSeqId = formatSeriesSeqId({
    code: exportedCode,
    seqYear: exportedSeqYear,
    seqNumber: exportedSeqNumber,
  });

  const { data } = useSerieInstanceUrlQuery({
    variables: {
      code: exportedCode,
      seqYear: exportedSeqYear,
      seqNumber: exportedSeqNumber,
    },
  });

  return (
    <FormattedAlert
      type="error"
      title="series_exchange.import.error.already_exists"
      titleValues={{
        ExportedLink: (part) =>
          match(data)
            .with({ serieInstanceUrl: P.select(P.not(P.nullish)) }, (url) => (
              <HighlightedAnchor href={url} target="_blank">
                {part}
              </HighlightedAnchor>
            ))
            .otherwise(() => part),
        exportedSeqId,
        exportedName,
        Link: (part) => (
          <HighlightedLink to={`/series/${seqId}`} target="_blank">
            {part}
            {aliases.length > 0 && (
              <>
                , <FormattedList value={aliases} />
              </>
            )}
          </HighlightedLink>
        ),
        seqId,
        name,
      }}
    >
      {props.children}
    </FormattedAlert>
  );
}
